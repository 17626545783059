
import { InputTypes } from "@/store/modules/input/input.types";
import { Component, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { DefaultState } from "../types";

const input = namespace("Input");

@Component({
  components: {
    InputList: () => import("@/components/input/InputList.vue"),
    UploadInputs: () => import("@/components/input/UploadInput.vue"),
  },
})
export default class InputsView extends Vue {
  @input.Mutation(InputTypes.SET_ADD_INPUT_DIALOG)
  public setAddInput!: (addInput: boolean) => void;

  @input.Mutation(InputTypes.SET_UPLOAD_INPUT_DIALOG)
  public setUploadInput!: (uploadInput: boolean) => void;

  @input.Action(InputTypes.LOAD_INPUTS)
  public loadInputs!: () => void;

  @input.State("loadingInput")
  public loadingInputs!: boolean;

  @input.State("upload")
  public uploadingInputs!: DefaultState;

  public pageSizes = [10, 15, 20];

  public pageSize = 15;

  public query = "";

  @Ref("inputList") public inputListRef!: { queryInputs(): void };

  refresh(): void {
    this.inputListRef.queryInputs();
  }

  openAddInputDialog(): void {
    this.setAddInput(true);
  }

  openUploadInputDialog(): void {
    this.setUploadInput(true);
  }
}
